* {
  box-sizing: border-box;
}

html {
  height: 100%;
  position: relative;
}

body {
  height: 100%;
  position: relative;
  background: url(./assets/coffee-apple.jpg) no-repeat center center fixed;
  -webkit-background: cover;
  -moz-background: cover;
  -o-background: cover;
  background-size: cover;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 90% !important;
  height: 100% !important;
  @media (max-width: 30em) {
    width: 50% !important;
    height: 100% !important;
  }
}
